import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ItemList from "../../components/ItemList";
import Hero from "./Hero";
import Banner from "./Banner";
import RFQ from "./RFQ";
import Category from "./Category";
import OurCustomers from "../../components/OurCustomers";
import Testimonial from "./Testimonial";
import { useNavigate } from "react-router-dom";

const Meta = () => {
  return (
    <Helmet>
      <title>FuelCab India - Online Doorstep Diesel Delivery</title>
      <meta
        name="description"
        content="Discover FuelCab, a leading provider of on-demand fuel delivery services. Learn about our mission, values, achievements, and global presence."
      />
      <meta
        name="keywords"
        content="FuelCab, on-demand fuel delivery, Doorstep Diesel Delivery, fuel management, fuel delivery services, mission, values, achievements, global presence"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://www.fuelcab.com",
            name: "FuelCab India",
            description:
              "FuelCab is a leading provider of on-demand fuel delivery services. We are dedicated to making fuel management easy and convenient for our customers.",
          }`}
      </script>
    </Helmet>
  );
};

const chooseRandomly = (listings, rfqs) => {
  if (listings.length > 0 && rfqs.length > 0)
    return Math.random() > 0.5 ? listings : rfqs;

  return listings.length > 0 ? listings : rfqs;
};

const Home = ({ user }) => {
  const {
    newListings,
    trendingListings,
    nearbyListings,
    featuredListings,
    solidListings,
    liquidListings,
    gasListings,
    FCIListings,
  } = useSelector((s) => s.listings);

  const {
    newRFQs,
    trendingRFQs,
    nearbyRFQs,
    featuredRFQs,
    solidRFQs,
    liquidRFQs,
    gasRFQs,
    FCIRFQs,
  } = useSelector((s) => s.RFQs);
  const navigate = useNavigate();

  const renderItemList = (baseTitle, listings, rfqs) => {
    let items;

    if (!user || (user.isBuyer && user.isSeller)) {
      items = chooseRandomly(listings, rfqs);
    } else if (user.isBuyer) {
      items = listings;
    } else if (user.isSeller) {
      items = rfqs;
    }

    const typeLabel = items === rfqs ? "RFQs" : "Listings";
    return (
      <ItemList title={`${baseTitle} ${typeLabel}`} items={items} showPrice />
    );
  };

  const handleExplore = (state) => {
    navigate("/explore", { state });
  };

  return (
    <main className="font-dmsans">
      <Meta />
      <Navbar />
      <div className="px-12">
        <Banner
          className="!mt-4"
          imageURL="/images/banner-1.png"
          href="https://play.google.com/store/apps/details?id=com.fuelcab.fuelcab&hl=en"
        />
        <Category />
        {renderItemList("Trending", trendingListings, trendingRFQs)}
        {renderItemList("New", newListings, newRFQs)}
        <Banner imageURL="/banner/1.png" />
        <ItemList
          title="Solid Listings"
          items={solidListings}
          showPrice
          onExplore={() => handleExplore({ categories: ["solid"], type: 0 })}
        />
        <ItemList
          title="Solid RFQs"
          items={solidRFQs}
          showPrice
          onExplore={() => handleExplore({ categories: ["solid"], type: 1 })}
        />
        <Banner imageURL="/banner/2.png" />
        <ItemList
          title="Liquid Listings"
          items={liquidListings}
          showPrice
          onExplore={() => handleExplore({ categories: ["liquid"], type: 0 })}
        />
        <ItemList
          title="Liquid RFQs"
          items={liquidRFQs}
          showPrice
          onExplore={() => handleExplore({ categories: ["liquid"], type: 1 })}
        />
        <Banner imageURL="/banner/3.png" />
        <ItemList
          title="Gas Listings"
          items={gasListings}
          showPrice
          onExplore={() => handleExplore({ categories: ["gas"], type: 0 })}
        />
        <ItemList
          title="Gas RFQs"
          items={gasRFQs}
          showPrice
          onExplore={() => handleExplore({ categories: ["gas"], type: 1 })}
        />
        {renderItemList("Near by", nearbyListings, nearbyRFQs)}
        {renderItemList("Featured", featuredListings, featuredRFQs)}
        {renderItemList("FCI", FCIListings, FCIRFQs)}
        <RFQ />
        <Banner imageURL="/banner/booking-app.png" />
        <Testimonial />
      </div>
      <OurCustomers />
      <Footer />
    </main>
  );
};

export default Home;
