import React from "react";
import { Helmet } from "react-helmet";

import Hero from "./Hero";
import History from "./History";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import OurCustomers from "../../components/OurCustomers";
import Service from "./Sectors";
import Areas from "./Areas";

const Meta = () => {
  return (
    <Helmet>
      <title>Diesel Booking - FuelCab India</title>
      <meta
        name="description"
        content="Discover FuelCab, a leading provider of on-demand fuel delivery services. Learn about our mission, values, achievements, and global presence."
      />
      <meta
        name="keywords"
        content="FuelCab, on-demand fuel delivery, fuel management, fuel delivery services, mission, values, achievements, global presence"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://www.fuelcab.com/booking",
            name: "FuelCab",
            description:
              "FuelCab is a leading provider of on-demand fuel delivery services. We are dedicated to making fuel management easy and convenient for our customers.",
          }`}
      </script>
    </Helmet>
  );
};

export default function Booking() {
  return (
    <>
      <Meta />
      <Navbar />
      <Hero />
      <History />
      <Service />
      <Areas />
      <div className={`md:px-14 px-4 mt-14 mb-12`}>
        <div className="rounded-md w-full md:min-h-[400px] flex justify-center items-center">
          <img
            src="/banner/booking-app.png"
            alt="app-download"
            className="py-8 border w-full h-full rounded-md transition-transform duration-300 transform hover:scale-105"
          />
        </div>
      </div>
      <OurCustomers />
      <Footer />
    </>
  );
}
