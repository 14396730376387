const CUSTOMERS_IMG = [
  "1.png",
  "2.webp",
  "3.png",
  "5.jpeg",
  "6.jpeg",
  "7.webp",
  "8.jpeg",
  "9.webp",
  "10.webp",
  "11.png",
  "12.webp",
  "13.webp",
  "14.png",
  "15.webp",
  "16.webp",
  "17.webp",
];

const OurCustomers = () => {
  return (
    <section className="font-dmsans w-full py-14 overflow-hidden">
      <div className="text-center mb-4">
        <h2 className="font-bold text-[25px] lg:text-[35px] leading-none mb-2">
          Meet Our Clients & Partners
        </h2>
        <p className="text-gray-400">Cients that never gets out of fuel.</p>
      </div>
      <div className="relative m-auto w-full overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
        <div className="animate-infinite-slider gap-14 flex w-[calc(250px*10)]">
          {CUSTOMERS_IMG.map((url, index) => (
            <img
              key={index}
              src={`customers/${url}`}
              alt={`Customer ${index + 1}`}
              className="slide w-32 h-auto filter grayscale object-contain"
            />
          ))}

          {CUSTOMERS_IMG.map((url, index) => (
            <img
              key={index}
              src={`customers/${url}`}
              alt={`Customer ${index + 1}`}
              className="slide w-32 h-auto filter grayscale object-contain"
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurCustomers;
