import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { openFeedbackModal } from "../../redux/store/modalsSlice";

export default function Footer() {
  const dispatch = useDispatch();

  const handleFeedback = () => {
    dispatch(openFeedbackModal());
  };

  const GROUPS = [
    {
      category: "About Us",
      links: [
        { title: "About Company", url: "/about-us" },
        { title: "Our Team", url: "/our-team" },
        { title: "Blogs", url: "/blogs" },
        { title: "Terms & Conditions", url: "/term-policy" },
        { title: "Return & Cancellation Policy", url: "/return-policy" },
        { title: "Ship & Delivery Policy", url: "/ship-policy" },
        { title: "Privacy Policy", url: "/privacy" },
      ],
    },
    {
      category: "Need Help",
      links: [
        { title: "Contact Us", url: "/contact-us" },
        { title: "Feedback", url: "/term-policy", onClick: handleFeedback },
        { title: "FAQ", url: "/faq" },
      ],
    },
    {
      category: "For Customers",
      links: [
        { title: "Nearby Products", url: "/explore" },
        { title: "Membership", url: "/dashboard/profile" },
      ],
    },
    {
      category: "Partner With Us",
      links: [
        { title: "Logistics Partnership", url: "/partnership" },
        { title: "FuelEnt/DDD Partnership", url: "/partnership" },
        { title: "Fuel Station Partnership", url: "/partnership" },
      ],
    },
  ];

  return (
    <footer className="font-dmsans px-8 pt-10 md:px-24 text-black bg-gray-50">
      <Link to="/">
        <img src="/images/fuelcab-logo.svg" className="h-[50px]" />
      </Link>
      <div className="mt-16 grid grid-cols-2 gap-6 md:gap-0 md:grid-cols-4">
        {GROUPS.map(({ category, links }) => {
          return (
            <div className="mt-4 md:mt-0">
              <h5 className="text-xl font-[500] text-[1rem] mb-4">
                {category}
              </h5>
              <div className="flex flex-col items-start gap-4">
                {links.map((link) => {
                  if (link.onClick) {
                    return (
                      <button
                        onClick={link.onClick}
                        className="hover:opacity-50 text-sm text-gray-500 text-[400]"
                      >
                        {link.title}
                      </button>
                    );
                  }
                  return (
                    <Link
                      to={`${link.url}`}
                      className="hover:opacity-50 text-sm text-gray-500 text-[400]"
                    >
                      {link.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex md:flex-row flex-col justify-center md:justify-between border-t-[0.5px] mt-8 py-6 items-center">
        <div className="flex items-center gap-4">
          <Link
            target="_blank"
            to="https://play.google.com/store/apps/details?id=com.fuelcab.fuelcab&hl=en"
          >
            <img src="/images/google-play.png" className="w-28" />
          </Link>
          <Link
            target="_blank"
            to="https://play.google.com/store/apps/details?id=com.fuelcab.fuelcab&hl=en"
          >
            <img src="/images/app-store.png" className="w-32" />
          </Link>
          <Link
            target="_blank"
            to="https://play.google.com/store/apps/details?id=com.fuelcab.fuelcab&hl=en"
          >
            <img src="/images/qr.png" className="w-12" />
          </Link>
        </div>
        <p className="md:mt-0 mt-2 font-[500] text-sm text-gray-500 text-center">
          © 2024 FuelCab India. All Rights Reserved.
        </p>
        <div className="grid grid-cols-4 gap-4">
          <a
            href="https://www.linkedin.com/company/fuelcab"
            target="_blank"
            className="cursor-pointer flex justify-center items-center bg-white rounded-[50%] h-[40px] w-[40px] border self-center"
          >
            <LinkedInIcon className="text-gray-500 hover:text-gray-600" />
          </a>

          <a
            href="https://m.facebook.com/Fuelcab/"
            target="_blank"
            className="cursor-pointer flex justify-center items-center bg-white rounded-[50%] h-[40px] w-[40px] border self-center"
          >
            <FacebookIcon className="text-gray-500 hover:text-gray-600" />
          </a>

          <a
            href="https://www.instagram.com/fuelcabindia/"
            target="_blank"
            className="cursor-pointer flex justify-center items-center bg-white rounded-[50%] h-[40px] w-[40px] border self-center"
          >
            <InstagramIcon className="text-gray-500 hover:text-gray-600" />
          </a>

          <a
            href="https://twitter.com/fuelcab"
            target="_blank"
            className="cursor-pointer flex justify-center items-center bg-white rounded-[50%] h-[40px] w-[40px] border self-center"
          >
            <TwitterIcon className="text-gray-500 hover:text-gray-600" />
          </a>
        </div>
      </div>
    </footer>
  );
}
